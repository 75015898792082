export enum StorageServiceKeys {
  interaction = 'interactionStorageKey',
  snAccessToken = 'snAccessTokenStorageKey',
  snRefreshToken = 'snRefreshTokenStorageKey',
  snAuthorizationCode = 'snAuthorizationCode',
  snInteraction = 'snInteractionStorageKey',
  snAccessTokenExpiration = 'snAccessTokenExpirationKey',
  somebodyIsPerformingAuthProcess = 'somebodyIsPerformingAuthProcess',
  TBD = 'snAuthRequestIsSentAlready',
  somebodyIsRefreshingToken = 'somebodyIsRefreshingToken'
}
