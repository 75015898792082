import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { StorageServiceKeys } from './Model/StorageServiceKeys';
import { HttpClient } from '@angular/common/http';
import { IOAuthTokenData } from './Model/IOauth2Model';
import { NOTIFICATION_TYPE, sendNotification } from '@amc-technology/davinci-api';
import { send } from 'process';

@Injectable()
export class TokenService {
  accessTokenObservable = new BehaviorSubject<string>('');
  serviceNowInstanceURL: string;
  AMCAppServiceNowURL: string;
  clientId: string;
  clientSecret: string;

  authCode: string
  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * 
   * @param accessToken 
   */
  setAccessToken(accessToken: string) {
    this.accessTokenObservable.next(accessToken);
  }


  getAccessToken(): string {
    return this.accessTokenObservable.value;
  }

  setRefreshTokenParams(
    serviceNowInstanceURL: string,
    AMCAppServiceNowURL: string,
    clientId: string,
    clientSecret: string
  ) {
    this.serviceNowInstanceURL = serviceNowInstanceURL;
    this.AMCAppServiceNowURL = AMCAppServiceNowURL;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
  }

  storeAccessAndRefreshToken(tokenData: IOAuthTokenData) {
    localStorage.setItem(StorageServiceKeys.snAccessToken, tokenData.access_token);
    localStorage.setItem(StorageServiceKeys.snRefreshToken, tokenData.refresh_token);
    this.setAccessToken(tokenData.access_token);

    const expires_in_seconds = Number.parseInt(tokenData.expires_in);
    const expires_in = (Date.now() + expires_in_seconds * 1000) + "";
    localStorage.setItem(StorageServiceKeys.snAccessTokenExpiration, expires_in);

  }

  getNewAccessTokenWithRefreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.somebodyIsRefreshingToken)) {
        localStorage.setItem(StorageServiceKeys.somebodyIsRefreshingToken, "true");
        let refresh_token = localStorage.getItem(StorageServiceKeys.snRefreshToken);
        try {
          this._http.post('RefreshToken', {
            clientId: this.clientId,
            clientSecret: this.clientSecret,
            instanceUrl: this.serviceNowInstanceURL,
            refreshToken: refresh_token
          }).subscribe(
            response => {
              this.storeAccessAndRefreshToken(response);
              localStorage.removeItem(StorageServiceKeys.somebodyIsRefreshingToken);
              resolve();
            },
            (error) => {
              localStorage.removeItem(StorageServiceKeys.somebodyIsRefreshingToken);
              reject();
            }
          )
        } catch (_) {
          localStorage.removeItem(StorageServiceKeys.somebodyIsRefreshingToken);
          reject();
        }
      } else {
        let timeout = 5;
        let timer = setInterval(async () => {
          // TODO1: what if the agent closes the window?
          if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
            clearInterval(timer);
            resolve();
          } else {
            timeout--;
            if (timeout <= 0) {
              clearTimeout(timer)
              reject();
            }
          }
        }, 1000);
      }

    })
  }

  getNewAccessTokenByOpeningNewWindow(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.somebodyIsPerformingAuthProcess)) {
        localStorage.setItem(StorageServiceKeys.somebodyIsPerformingAuthProcess, "true");

        const body = {
          ServiceNowInstanceURL: `${this.serviceNowInstanceURL}`,
          AMCAppServiceNowURL: `${this.AMCAppServiceNowURL}`,
          ClientId: `${this.clientId}`
        }

        this._http.post<string>("/guids", body).subscribe(
          async (response) => {
            let newWindow = this.openAuthCodeWindow(response["guid2"]);
            try {
              let rsp = await this.pollCodeEndpoint(response, 30);
              this.authCode = rsp.code;
              await this.getNewAccessTokenWithAuthCode();
              localStorage.removeItem(StorageServiceKeys.somebodyIsPerformingAuthProcess);
              resolve();
            } catch {
              newWindow.close();
              localStorage.removeItem(StorageServiceKeys.somebodyIsPerformingAuthProcess);
              sendNotification("OAuth process failed! Please click the \"Allow\" button in the window that opened.s", NOTIFICATION_TYPE.Warning);
              reject()
            }
          },
          (error) => {
            localStorage.removeItem(StorageServiceKeys.somebodyIsPerformingAuthProcess);
            reject();
          }
        )
      } else {
        let timeout = 30;
        let timer = setInterval(async () => {
          // TODO1: what if the agent closes the window?
          if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
            clearInterval(timer);
            resolve();
          } else {
            timeout--;
            if (timeout <= 0) {
              clearTimeout(timer)
              reject();
            }
          }
        }, 1000);
      }
    });
  }



  getNewAccessTokenWithAuthCode(): Promise<void> {
    return new Promise((resolve, reject) => {
      const code = this.authCode;
      try {
        this._http.post('Auth', {
          code: code,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          instanceUrl: this.serviceNowInstanceURL,
          redirectUri: this.AMCAppServiceNowURL
        }).subscribe(
          response => {
            this.storeAccessAndRefreshToken(response);
            resolve();
          },
          _ => {
            // Handle errors
            reject();
          }
        )
      } catch (error) {
        reject();
      }
    })
  }

  openAuthCodeWindow(state: string) {
    let windowHandle = window.open(
      `${this.AMCAppServiceNowURL}/redirectagent?state=${state}`,
      // `${this.serviceNowInstanceURL}/oauth_auth.do?response_type=code&redirect_uri=${this.AMCAppServiceNowURL}&client_id=${this.clientId}&state=${state}`,
      '_blank'
    );
    return windowHandle;
  }



  async pollCodeEndpoint(guidresponse: any, maxAttempts: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < maxAttempts; i++) {
        try {
          let response = await this._http.post("/getcode", {
            "Guid1": guidresponse.guid1,
            "Guid2": guidresponse.guid2,
          }).toPromise();
          if (response && response.hasOwnProperty("code")) {
            return resolve(response);
          }
        } catch (error) {

        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      reject();
    });
  }

}
